/* Login.css */
html,
body {
  height: 100%;
}

.login-background {
  background-image: url('/public/images/login-background.webp'); /* Update path if image is in src */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-signin {
  max-width: 450px; /* Adjust the form width to make it larger */
  width: 90%; /* Ensures responsiveness on smaller screens */
  padding: 2rem; /* More padding for visual spacing */
  background: rgba(255, 255, 255, 0.8); /* Optional: Add a slight background for contrast */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="text"],
.form-signin input[type="password"] {
  margin-bottom: 1rem;
  border-radius: 4px;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }

  /* Increase form width for larger screens */
  .form-signin {
    max-width: 500px;
  }
}

/* Button styling */
.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

/* Wizard navigation buttons */
.wizard-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.wizard-nav .btn {
  width: 48%;
}

/* Previous link styling */
/* change button to link */
.app--previous-link {
  color: #6c757d!important;
  text-decoration: none!important;
}

.app--previous-link:hover {
  color: #dc3545!important;
}

input.app--pin-code {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 6px;
  padding: 1rem!important;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1rem 0;
  color:var(--bs-secondary-color);
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--bs-secondary-color);
  margin: 0 1rem;
  opacity: 0.5;
}

.app--link {
  color: #d42528;
  text-decoration: none;
}

.app--link:hover {
  text-decoration: underline;
}
